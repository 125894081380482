import shippingCostsJson from '../config/delivery-methods.json'
import type { CartItem } from '~/types/item'
import type { DeliveryMethod } from '~/types/checkout'

export function useShipping() {
  const getShippingCosts = (itemShippingCosts: number[]): DeliveryMethod[] => {
    const parsedShippingMethodsAndCosts: DeliveryMethod[] = []

    // Custom band-aid: Add general Placeholder shipping methods for Store pickup and Warehouse pickup
    let addPickUpFromTemplate = false
    let addPickUpFromWarehouseTemplate = false
    const pickupTemplate: DeliveryMethod = {
      id: 'VekePickup',
      label: 'Toimitus myymälään tai noutopisteelle',
      price: 0,
      group: 'VekePickup',
      description: '5-10 arkipäivää'
    }
    const pickupWarehouseTemplate: DeliveryMethod = {
      id: 'VekePickupWH',
      label: 'Toimitus keskusvarastolle',
      price: 0,
      group: 'VekePickup',
      description: '3-10 arkipäivää'
    }

    itemShippingCosts.forEach((itemShippingCode: string) => {
      // @ts-ignore
      const parsedShipping = shippingCostsJson.find((cost: DeliveryMethod) => cost.id === itemShippingCode)
      if (parsedShipping) {
        // Custom band-aid: Check if pickup methods are allowed and does it contain warehouse or in-store methods
        if (parsedShipping.group === 'VekePickup') {
          if (['L1', 'NRAK'].includes(parsedShipping.id)) { // warehouse pickups
            addPickUpFromWarehouseTemplate = true
          }
          else { // in-store pickups
            addPickUpFromTemplate = true
            pickupTemplate.price = parsedShipping.price > pickupTemplate.price ? parsedShipping.price : pickupTemplate.price
          }
        }
        else { // regular shipping methods
          // @ts-ignore
          parsedShippingMethodsAndCosts.push(parsedShipping)
        }
      }
    })

    if (addPickUpFromTemplate) {
      parsedShippingMethodsAndCosts.push(pickupTemplate)
    }
    if (addPickUpFromWarehouseTemplate) {
      parsedShippingMethodsAndCosts.push(pickupWarehouseTemplate)
    }

    parsedShippingMethodsAndCosts.sort((a, b) => a.price - b.price)
    return parsedShippingMethodsAndCosts
  }

  const getCartMaxShippingPrices = (cartItems: CartItem[]): DeliveryMethod[] => {
    const cartItemWithOnlyPickupDelivery = cartItems.find(item => item.shipping.length && item.shipping.every(shipping => shipping.group === 'StorePickup'))

    if (cartItemWithOnlyPickupDelivery) {
      return cartItemWithOnlyPickupDelivery.shipping
    }

    const deliveryMethodsForCart = cartItems.reduce((acc: DeliveryMethod[], item) => {
      if (item.shipping.length > 0 && (acc.length === 0 || item.shipping[0].price > acc[0].price)) {
        acc = Object.assign([], item.shipping)
      }
      return acc
    }, [])

    return deliveryMethodsForCart.sort((a, b) => (a.price > b.price || a.price === 0) ? 1 : -1)
  }

  const getLowestMaxShippingPrice = (shippingCosts: DeliveryMethod[]): number => {
    const prices: number[] = []
    shippingCosts.forEach((shippingCost) => {
      // ignore zero priced shippings
      if (shippingCost.price > 0) {
        prices.push(shippingCost.price)
      }
    })
    return prices.length > 0 ? Math.min(...prices) : 0
  }

  return {
    getShippingCosts,
    getCartMaxShippingPrices,
    getLowestMaxShippingPrice
  }
}
